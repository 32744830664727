import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import thang51 from "../../assets/thang5_1.jpg";
import thang52 from "../../assets/thang5_2.jpg";

import thang4 from "../../assets/thang4.jpg";
import thang3 from "../../assets/thang3.jpg";
// import thang3 from "../../assets/THANG3.jpg";
import china from "../../assets/trendchina.png";

import success from "../../assets/mainsection-bg.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./success.scss";

// import required modules
import { Pagination, Navigation } from "swiper";

const Success = () => {
  const [swiperRef, setSwiperRef] = useState(null);

  return (
    <section className="mx-6 lg:mx-20 md:mx-20" id="success">
      <h2 className="text-center text-white font-bold text-2xl">
        THÀNH TỰU ĐẠT ĐƯỢC
      </h2>
      {/* <Swiper
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>Slide 1</SwiperSlide>
        <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide>
        <SwiperSlide>Slide 7</SwiperSlide>
        <SwiperSlide>Slide 8</SwiperSlide>
        <SwiperSlide>Slide 9</SwiperSlide>
      </Swiper> */}
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide className="">
          <img className="" src={thang3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={thang4} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={thang52} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={thang51} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={china} alt="" />
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Success;
